.body {
  margin-top: 20px;
  margin-left: 40px;
}
.body .table-container {
  margin-top: 22px;
}
.body .ranking {
  margin-top: 36px;
}

.table-container {
  height: 419px;
}

.table th {
  height: 89px !important;
  font-size: 18px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none)  {font-size: 1.125rem;}
}
.table td {
  font-size: 14px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none)  {font-size: 0.875rem;}
}
.table td:nth-child(1),
.table td:nth-child(2),
.table td:nth-child(4) {
  padding: 18px 0px;
}

.buttons {
  margin-left: 15px;
  display: flex;
  column-gap: 15px;
}

.ranking {
  position: relative;
  display: flex;
  align-items: flex-end;
  column-gap: 70px;

  width: 1440px;
  min-height: 264px;
  padding: 16px 40px;
  border: 5px solid var(--tomopla-color);
  border-radius: 8px;
  background-color: var(--tomopla-color-light);
}
.ranking .title {
  position: absolute;
  top: -5px;
  left: 40px;
}

.title {
  display: grid;
  place-content: center;
  width: 440px;
  height: 80px;
  border-radius: 0 0 8px 8px;
  background-color: var(--tomopla-color);
  color: var(--white);

  font-size: 24px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none)  {font-size: 1.5rem;}
  font-family: var(--main-font-family-db);
  
  line-height: 32px;
  letter-spacing: 0.01em;
  white-space: pre-wrap;
  text-align: center;
}

.ranking .form {
  margin-left: 10px;
  width: 430px;
}
.form {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 28px;
}

.form .select {
  width: 298px;
  justify-content: space-between;
}
.form .select * {
  font-size: 16px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none)  {font-size: 1rem;}
  white-space: nowrap;
}
.form .select > :not(:first-child) {
  width: 195px;
  height: 42px;
}
.form .select img {
  height: 9px;
}

.form .submit {
  position: absolute;
  right: -58px;
  bottom: 5px;
  width: 100px !important;
}

.word-list {
  flex: 1;
  border-radius: 4px;
  border: 1px solid var(--line-color);
  overflow: hidden;
}

.word-list li {
  height: 60px;
  display: flex;
}
.word-list li > * {
  height: 100%;
  display: flex;
  align-items: center;
}
.word-list li:not(:last-child) {
  border-bottom: 1px solid var(--line-color);
}

.word-list .caption {
  justify-content: center;
  width: 79px;
  height: 100%;
  border-right: 1px solid var(--line-color);
  background-color: var(--tomopla-color);
  color: var(--white);

  font-size: 20px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none)  {font-size: 1.25rem!important;}
  font-family: var(--main-font-family-db);
  letter-spacing: 0.05em;
  line-height: 1;
}

.word-list .content {
  flex: 1;
  padding:0 20px;
  background-color: var(--white);

  font-size: 22.5px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none)  {font-size: 1.5625rem;}
  font-family: var(--main-font-family-db);
  letter-spacing: 0.1em;
  line-height: 1.2;
}
