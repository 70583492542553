/* .notification {
  display: flex;
  width: 100%;
  height: 107px;
  background: var(--tomopla-color);
  padding: 3px;
  border-radius: 16px;

  font-family: var(--main-font-family-db);
  
} */

.icon {
  height: 100%;
  width: 167px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 12px;

  color: var(--white);
  font-size: 25px;
  @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none){font-size: 1.5625rem;};
  /* letter-spacing: 0.05em; */
}
.icon img {
  width: 34px;
  height: 39px;
}

.icon .row{
  /* display: flex;
  flex-direction: row;
  align-items: baseline; */
  margin-top: -5px;
}

/* .items-container {
  flex: 1;
  height: 100%;
  padding: 14px 15px 13px;
  border-radius: 0 16px 16px 0;
  background: var(--tomopla-color-light);
}
.items-container > div {
  height: 100%;
  overflow-x: auto;
}
.items-container > div * {
  color: var(--letter-black);
  font-size: 20px;
  
  font-family: var(--main-font-family-db);
  letter-spacing: 0.05em;
  line-height: 36px;
}

.items-container > div::-webkit-scrollbar {
  background-color: var(--gray);
  width: 12px;
  border-radius: 20px;
}
.items-container > div::-webkit-scrollbar-thumb {
  background-color: var(--dark-gray-2);
  border-radius: 20px;
} */
