import {
  Column,
  ColumnMeta,
  Header,
  HeaderGroup,
  Table as ReactTable,
  Row as ReactTableRow,
  RowData,
  flexRender,
} from '@tanstack/react-table';
import clsx from 'clsx';
import {
  CSSProperties,
  ReactNode,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import styled, { DefaultTheme } from 'styled-components';
import styles from './styles.module.css';

export const TableWithScroll = <T extends RowData>({
  table,
  minWidth,
  onClickRow,
  ...props
}: Omit<TableProps<T>, 'width'> &
  Omit<TableScrollCotainerProps, 'children'>) => (
  <TableScrollCotainer {...props}>
    <Table
      table={table}
      width={props.maxWidth! - 7}
      minWidth={minWidth}
      onClickRow={onClickRow}
    />
  </TableScrollCotainer>
);
// eslint-disable-next-line react/display-name
export const TableWithInfniteScroll = forwardRef<
  TableInfiniteScrollCotainerRefs,
  Omit<TableProps<any>, 'width'> &
  Omit<TableScrollCotainerProps, 'children'> & {
    // onScroll: React.UIEventHandler<HTMLDivElement>;
  }
>(({ table, minWidth, onClickRow, ...props }, refs) => (
  <TableInfiniteScrollCotainer {...props} ref={refs}>
    {/* TODO ref to table  */}
    <Table
      table={table}
      width={props.maxWidth! - 7}
      minWidth={minWidth}
      onClickRow={onClickRow}
    />
  </TableInfiniteScrollCotainer>
));

export type TableProps<T> = {
  className?: string;
  table: ReactTable<T>;
  width?: number;
  minWidth?: number;
  onClickRow?: (rowData: T) => void;
  theme?: DefaultTheme;
};

const TableWrap = styled('table')(
  ({ theme }) => `

    border: 1px solid var(${theme.textcolor});
    border-radius: 4px;

    &._2q-qcW_caption-table{
      tbody{
        tr:last-child {
          td:last-child {
            background-color:var(${theme.backgroud})!important;
          }
        }
      }
    }

    &:last-child {
      tbody{
        td:last-child {
          color: var(${theme.textcolor})
        }
      }
    }
    thead {
      position: sticky;
      top: 0;
      z-index: 2;
      tr:first-child th:first-child {
        position: sticky;
        top: 0;
        left: 0;
      }
      th {
        position: relative;
        height: 45px;
      
        background-color: var(${theme.maincolor});
        color: var(${theme.textcolreverse});
        font-size: 17px;
        @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.125rem;};
        line-height: 26px;
        letter-spacing: 0.1em;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        //white-space: pre-wrap;

        border-right: 1px solid var(${theme.textcolor});
        border-bottom: 1px solid var(${theme.textcolor});

        &:last-child {
          border-right: none;
        }
      }      
    }
    tbody{
      td {
        height: 46px;
        padding: 15px;    
        background-color: var(${theme.backgroud});
        color: var(${theme.textcolor});
        font-size: 13px;
        @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.125rem;};
        letter-spacing: 0.1em;
        text-align: center;
        vertical-align: middle;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-right: 1px solid var(${theme.textcolor});
        border-bottom: 1px solid var(${theme.textcolor});
        .no-padding {
          margin: -15px;
        }
        &:last-child {
          border-right: none;
          color: var(${theme.textcolor})
        }
      }
    }
    tr:last-child td {
      border-bottom: none;
      color: var(${theme.textcolor})
    }
    &.sthistory{
      tbody{
        td:last-child{
          background-color: var(${theme.lightcolor});
          color: var(${theme.textcolor})
        }
        tr:last-child {
          td{
            background-color: var(${theme.backgroud});
            color: var(${theme.textcolor});
            &:last-child{
              background-color: var(${theme.lightcolor})!important;
            }
            &:first-child{
              background-color:var(${theme.backgroud})!important;
            }
          }
        }
      }
    }
    &.sthistory:last-child{
      tbody{
        td:last-child {
          background-color: var(${theme.lightcolor});
          color: var(${theme.textcolor})
        }
      }
    }
  `,
);

export const Table = <T extends RowData>({
  className,
  table,
  width,
  minWidth,
  onClickRow,
  theme,
}: TableProps<T>): JSX.Element => {
  return (
    <TableWrap
      className={clsx(className, styles.table)}
      style={{ minWidth, width }}
    >
      <colgroup>
        {table.getAllLeafColumns().map((header) => (
          <col key={header.id} style={{ width: header.getSize() }} />
        ))}
      </colgroup>
      <thead>
        {table.getHeaderGroups().map((headerGroup, _, headerGroups) => (
          <HeaderRow
            key={headerGroup.id}
            headerGroup={headerGroup}
            maxDepth={headerGroups.length}
          />
        ))}
      </thead>
      <tbody>
        {table
          .getRowModel()
          .rows.map((row, i, rows) =>
            row.subRows.length ? (
              row.subRows.map((subRow, depth, subRows) => (
                <RowWithSubRows
                  key={`${row.id}_${subRow.id || depth}`}
                  subRow={subRow}
                  depth={depth}
                  maxDepth={subRows.length - 1}
                  isLastRow={i === rows.length - 1}
                  onClickRow={onClickRow}
                />
              ))
            ) : (
              <Row key={row.id} row={row} onClickRow={onClickRow} />
            ),
          )}
      </tbody>
    </TableWrap>
  );
};

export type TableScrollCotainerProps = {
  className?: string;
  maxWidth?: number;
  maxHeight?: number;
  children: ReactNode;
};
export const TableScrollCotainer = ({
  className,
  maxWidth,
  maxHeight,
  children,
}: TableScrollCotainerProps) => (
  <div className={clsx(className, styles['table-container'])}>
    <div
      className={clsx(styles['table-scroll-container'])}
      style={{ maxWidth, maxHeight }}
    >
      {children}
    </div>
  </div>
);

export type TableInfiniteScrollCotainerRefs = {
  tableContainer: HTMLDivElement | null;
  marker: HTMLDivElement | null;
};

// eslint-disable-next-line react/display-name
export const TableInfiniteScrollCotainer = forwardRef<
  TableInfiniteScrollCotainerRefs,
  TableScrollCotainerProps
>(({ className, maxWidth, maxHeight, children }, ref) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const markerRef = useRef<HTMLDivElement>(null);
  useImperativeHandle(ref, () => ({
    get tableContainer() {
      return tableContainerRef.current;
    },
    get marker() {
      return markerRef.current;
    },
  }));
  return (
    <div className={clsx(className, styles['table-container'])}>
      <div
        className={clsx(styles['table-infinite-scroll-container'])}
        style={{ maxWidth, maxHeight }}
        ref={tableContainerRef}
      >
        <div className={clsx(styles['wrapper'])}>
          {children}
          <div className={clsx(styles['marker'])} ref={markerRef} />
        </div>
      </div>
    </div>
  );
});

const HeaderRow = ({
  headerGroup,
  maxDepth,
}: {
  headerGroup: HeaderGroup<any>;
  maxDepth: number;
}) => (
  <tr>
    {headerGroup.headers.map((header) => {
      const { id, column } = header;
      const depth = getDepth(column);
      if (header.depth - 1 > depth) return;
      const { columnDef } = column;
      const sortedDirection = column.getIsSorted();
      return (
        <th
          key={id}
          colSpan={header.subHeaders.length || undefined}
          rowSpan={
            columnDef.meta?.thRowSpan ||
            (hasSubHeaders(header) ? 1 : maxDepth - depth)
          }
          style={{
            ...stickyLeftStyle(columnDef.meta),
          }}
          onClick={
            columnDef.enableSorting
              ? () => column.toggleSorting(sortedDirection === 'asc', true)
              : undefined
          }
          className={clsx(columnDef.enableSorting && styles.clickable)}
        >
          {flexRender(columnDef.header, header.getContext())}
          {columnDef.enableSorting && (
            <span className={styles['sort-icon-container']}>
              <img
                src="/img/vector-arrow.svg"
                className={clsx(
                  styles['sort-icon'],
                  sortedDirection && styles[sortedDirection],
                )}
              />
            </span>
          )}
        </th>
      );
    })}
  </tr>
);

const RowWithSubRows = ({
  subRow,
  depth,
  maxDepth,
  isLastRow,
  onClickRow,
}: {
  subRow: ReactTableRow<any>;
  depth: number;
  maxDepth: number;
  isLastRow: boolean;
  onClickRow?: (rowData: any) => void;
}) => (
  <tr>
    {subRow.getVisibleCells().map(({ id, column, getContext }) => {
      const {
        columnDef: { meta = {}, cell },
      } = column;

      

      if (meta.tdRowSpan && depth % meta.tdRowSpan !== 0) return;

      return (
        <td
          key={id}
          className={clsx(onClickRow && styles.clickable)}
          style={{
            textAlign: meta.align,
            // remove border bottom of the last parent row
            borderBottom:
              isLastRow &&
                meta.tdRowSpan &&
                depth + meta.tdRowSpan - 1 === maxDepth
                ? 'none'
                : undefined,
            ...stickyLeftStyle(meta),
          }}
          rowSpan={meta.tdRowSpan}
          onClick={onClickRow ? () => onClickRow(subRow.original) : undefined}
        >
          {flexRender(cell, getContext())}
        </td>
      );
    })}
  </tr>
);

const Row = ({
  row,
  onClickRow,
}: {
  row: ReactTableRow<any>;
  onClickRow?: (rowData: any) => void;
}) => {
  return (
    <tr>
      {row.getVisibleCells().map(({ id, column, getContext }) => {
        const {
          columnDef: { meta = {}, cell },
        } = column;
        return (
          <td
            key={id}
            className={clsx(onClickRow && styles.clickable)}
            style={{
              textAlign: meta.align,
              ...stickyLeftStyle(meta),
            }}
            rowSpan={meta.tdRowSpan}
            onClick={onClickRow ? () => onClickRow(row.original) : undefined}
          >
            {flexRender(cell, getContext())}
          </td>
        );
      })}
    </tr>
  );
};

const getDepth = (column: Column<any>): number => {
  let current = column.parent;
  for (let depth = 0; ; depth++) {
    if (!current) return depth;
    current = current.parent;
  }
};
const hasSubHeaders = (header: Header<any, any>): boolean => {
  return (
    header.subHeaders.length > 0 && header.subHeaders[0].id !== header.column.id
  );
};

const stickyLeftStyle = (
  meta: ColumnMeta<any, any> | undefined,
): CSSProperties =>
  meta?.stickyLeft == null
    ? {}
    : { position: 'sticky', left: meta.stickyLeft, zIndex: 1 };
